/**
 * @name: 商城管理-邀请海报模板管理接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商城管理-邀请海报模板管理接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IPoster, IPosterParams } from "./types";

export const posterPageApi = (params: IPosterParams) => get<IPageRes<IPoster[]>>('/admin/invitePoster/query', params)

export const posterCreateApi = (data: Partial<IPoster>) => postJ('/admin/invitePoster/create', data)

export const posterModifyApi = (data: Partial<IPoster>) => postJ('/admin/invitePoster/modify', data)
